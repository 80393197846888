import { Link } from "gatsby";
import React from "react";
import { Card, Tag } from "@blueprintjs/core";

export const BlogPostCard = ({ excerpt, frontmatter, fields }) => {
    const { tags = [], slug, title, date } = frontmatter;
    return (
        <Link to={`${slug}/`}>
            <Card className="blog-post-card" interactive key={slug}>
                <div className="blog-post-card-title">{title}</div>

                <div className="blog-post-meta">
                    <div className="blog-post-meta-datetime">
                        <div className="blog-post-meta-date">{date}</div>
                        <div>|</div>
                        <div className="blog-post-meta-reading-time">{fields.readingTime.text}</div>
                    </div>
                    <div className="blog-post-card-tags">
                        {tags.map((tag) => {
                            return (
                                <Tag minimal key={tag}>
                                    {tag}
                                </Tag>
                            );
                        })}
                    </div>
                </div>

                <div className="blog-post-card-excerpt bp4-text-muted ">
                    <em>{frontmatter.excerpt || excerpt}</em>
                </div>
            </Card>
        </Link>
    );
};
