import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { BlogPostCard } from "../components/blog-post-card";
import { SEO, SeoObject } from "../components/seo";

export const TagPage = ({ pageContext, data }) => {
    let meta: SeoObject = {
        description: `Posts tagged: "${pageContext.tag}"`,
        title: `Posts tagged: ${pageContext.tag} - jracollins.com`,
    };

    return (
        <Layout>
            <SEO meta={meta} />
            <div className="row-gap">
                <div className="blog-posts-header">
                    <h1>
                        Posts tagged: "{pageContext.tag}" ({pageContext.totalCount})
                    </h1>
                </div>
                <div className="wrapper row-gap blog-posts">
                    {data.allMdx.nodes.map(({ excerpt, frontmatter, fields, id }) => (
                        <BlogPostCard {...{ excerpt, frontmatter, fields }} key={id} />
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default TagPage;

export const mdxLayoutTemplateQuery = graphql`
    query TagPageByTagName($tag: String!, $status: [String]!) {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { status: { in: $status }, type: { eq: "blogPost" }, tags: { in: [$tag] } } }
            limit: 20
        ) {
            nodes {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    title
                    slug
                    tags
                    date(formatString: "DD MMMM YYYY")
                    relativeDate: date(fromNow: true)
                    isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                }
                fields {
                    readingTime {
                        text
                    }
                }
            }
        }
    }
`;
